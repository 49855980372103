import { render } from "./WorkspaceList.vue?vue&type=template&id=65632a24"
import script from "./WorkspaceList.vue?vue&type=script&lang=ts"
export * from "./WorkspaceList.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QSpace from 'quasar/src/components/space/QSpace.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSpace,QList,QItem,QItemSection,QItemLabel,QBtn});qInstall(script, 'directives', {Ripple});
