
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { WorkspaceModel, IWorkspace, IWorkspacePermission } from '@/components/workspace/workspace-model'
import CLoading from '@/components/common/ui/CLoading.vue'
import { EUserRole, UserModel } from '@/components/user/user-model'
import WorkspaceMixin from '@/components/workspace/mixins/WorkspaceMixin.vue'
import WorkspaceFormModal from '@/components/workspace/mixins/WorkspaceFormModal.vue'

@Options({
  components: { WorkspaceFormModal, CLoading },
  directives: { maska },
})
export default class WorkspaceView extends mixins(WorkspaceMixin) {
  modalWorkspaceFormVisible = false
  selectedWorkspace: IWorkspace = new WorkspaceModel({})

  get loading() {
    return this.$store.state.workspace.loading
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo || {}
  }

  get workspaceItems(): IWorkspace[] {
    return this.$store.getters.workspaces || []
  }

  get workspace() {
    return this.$store.getters.workspace
  }

  get permissions(): IWorkspacePermission[] {
    return this.workspace.permissions || []
  }

  get isOwner() {
    // TODO: Use workspace model instead
    return (
      this.userInfo?._id &&
      this.permissions.find(
        (per: IWorkspacePermission) => per.userId === this.userInfo._id && per.role === EUserRole.owner
      )
    )
  }

  onDelete(workspace: IWorkspace) {
    this.$q
      .dialog({
        title: 'Confirm',
        message: 'Are you sure you want to delete?',
        cancel: true,
        persistent: true,
      })
      .onOk(() => {
        this.removeWorkspace(workspace as WorkspaceModel)
      })
  }

  onEdit(workspace: IWorkspace) {
    this.selectedWorkspace = workspace
    this.modalWorkspaceFormVisible = true
  }

  onCloseModal() {
    this.selectedWorkspace = new WorkspaceModel({})
    this.modalWorkspaceFormVisible = false
  }

  created() {
    this.$meta.setMeta({ title: ['Workspaces'] })
    this.getWorkspaces()
  }
}
