<template>
  <section v-if="!loading" class="q-pa-lg">
    <div class="row no-wrap">
      <div>
        <h1 class="text-h4">Your workspaces</h1>
      </div>
    </div>

    <q-space class="q-my-md" />

    <div class="container-smaller">
      <q-list bordered separator class="rounded-borders bg-grey-1">
        <template v-for="(workspace, index) in workspaceItems" :key="index">
          <q-item clickable v-ripple v-if="workspace.canRead">
            <q-item-section class="text-weight-medium" @click="gotoWorkspace(workspace._id)">
              {{ workspace.title }}
              <q-item-label caption lines="2">{{ workspace.description }}</q-item-label>
            </q-item-section>

            <q-item-section side v-if="workspace.canWrite">
              <div class="q-gutter-sm">
                <q-btn size="sm" round icon="edit" @click="onEdit(workspace)" />
                <q-btn size="sm" round color="red" icon="delete" @click="onDelete(workspace)" />
              </div>
            </q-item-section>
          </q-item>
        </template>
      </q-list>

      <q-space class="q-my-md" />
      <q-btn color="primary" icon="add" @click.prevent="modalWorkspaceFormVisible = true" label="New workspace" />
    </div>
  </section>
  <CLoading v-else />

  <WorkspaceFormModal
    v-if="modalWorkspaceFormVisible"
    :workspaceData="selectedWorkspace"
    :modalVisible="modalWorkspaceFormVisible"
    @update:closeModal="onCloseModal"
  />
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { WorkspaceModel, IWorkspace, IWorkspacePermission } from '@/components/workspace/workspace-model'
import CLoading from '@/components/common/ui/CLoading.vue'
import { EUserRole, UserModel } from '@/components/user/user-model'
import WorkspaceMixin from '@/components/workspace/mixins/WorkspaceMixin.vue'
import WorkspaceFormModal from '@/components/workspace/mixins/WorkspaceFormModal.vue'

@Options({
  components: { WorkspaceFormModal, CLoading },
  directives: { maska },
})
export default class WorkspaceView extends mixins(WorkspaceMixin) {
  modalWorkspaceFormVisible = false
  selectedWorkspace: IWorkspace = new WorkspaceModel({})

  get loading() {
    return this.$store.state.workspace.loading
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo || {}
  }

  get workspaceItems(): IWorkspace[] {
    return this.$store.getters.workspaces || []
  }

  get workspace() {
    return this.$store.getters.workspace
  }

  get permissions(): IWorkspacePermission[] {
    return this.workspace.permissions || []
  }

  get isOwner() {
    // TODO: Use workspace model instead
    return (
      this.userInfo?._id &&
      this.permissions.find(
        (per: IWorkspacePermission) => per.userId === this.userInfo._id && per.role === EUserRole.owner
      )
    )
  }

  onDelete(workspace: IWorkspace) {
    this.$q
      .dialog({
        title: 'Confirm',
        message: 'Are you sure you want to delete?',
        cancel: true,
        persistent: true,
      })
      .onOk(() => {
        this.removeWorkspace(workspace as WorkspaceModel)
      })
  }

  onEdit(workspace: IWorkspace) {
    this.selectedWorkspace = workspace
    this.modalWorkspaceFormVisible = true
  }

  onCloseModal() {
    this.selectedWorkspace = new WorkspaceModel({})
    this.modalWorkspaceFormVisible = false
  }

  created() {
    this.$meta.setMeta({ title: ['Workspaces'] })
    this.getWorkspaces()
  }
}
</script>
