
import { mixins, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import QForm from 'quasar/src/components/form/QForm.js';
import { WorkspaceModel, IWorkspace } from '@/components/workspace/workspace-model'

import WorkspaceMixin from '@/components/workspace/mixins/WorkspaceMixin.vue'
import logging from '@/utils/logging'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class WorkspaceFormModal extends mixins(WorkspaceMixin) {
  @Prop({ default: {} })
  workspaceData!: WorkspaceModel

  @Prop()
  modalVisible!: boolean

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  requiredRule = {
    message: 'This field is required',
  }

  form: IWorkspace = {
    title: '',
    description: '',
  }

  rules = {
    title: [this.requiredRule],
  }

  get loading() {
    return this.$store.state.workspace.loading
  }

  get isNew() {
    return !this.workspaceData?._id
  }

  get modalTitle() {
    return this.isNew ? 'Create new Workspace' : 'Edit workspace'
  }

  onSubmitWorkspace() {
    const form = this.$refs.formRef as QForm
    form
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }
        await this.doSaveWorkspace()
        this.onCancel()
      })
      .catch((error: unknown) => {
        logging.debug(error)
      })
  }

  async doSaveWorkspace() {
    const workpace = this.form as WorkspaceModel
    if (this.isNew) {
      return this.addWorkspace(workpace)
    } else {
      return this.updateWorkspace(workpace)
    }
  }

  onCancel() {
    this.visible = false
  }

  async created() {
    this.form = { ...this.form, ...this.workspaceData.serialize() }
  }
}
